import GameContract, { TransactionAction } from "../../../model/contract/GameContract";
import EOSClient from "../../../model/RPCClient";
import Mining, { MiningResult } from "./Mining";

/**
 * https://wax.bloks.io/account/m.federation
 * 
 * https://wax.bloks.io/account/m.federation
 * 
 * 
 * qwnho.wam
 * 
 */

export interface Miner {
  current_land: string,
  last_mine: string,
  last_mine_tx: string,
  miner: string,
}



export default class AlienWorldsContract extends GameContract {
  static NAME = 'm.federation';
  static CONTRACT_NAME = 'm.federation'
  static COLLECTION_NAME = 'm.federation';
  static TOKEN_CONTRACT = 'alien.worlds';

  protected _miner: Miner;
  protected _miningResult: MiningResult;
  protected _lastTransactionTx: string;

  constructor(client:EOSClient) {
    super(client);

    this._name = AlienWorldsContract.NAME;
    this._contractName = AlienWorldsContract.CONTRACT_NAME;
    this._tokenContract = AlienWorldsContract.TOKEN_CONTRACT;
    this._defaultSymbol = 'TLM';
    
    this._lastTransactionTx = '';
        
    this._miningResult = {
      account: '',
      hex_digest: '',
      rand_str: ''
    };

    this._miner = {
      current_land: '',
      last_mine: '',
      last_mine_tx: '',
      miner: ''
    };

  }

  protected async loadMiner() {
    const client = this._client;
    try {
      const results = await client.rpc.get_table_rows({
        json: true,
        code: AlienWorldsContract.CONTRACT_NAME,
        scope: AlienWorldsContract.CONTRACT_NAME,
        table: 'miners',
        lower_bound: client.userAccount,
        upper_bound: client.userAccount,
        limit: 1,
        reverse: false,
        show_payer: false
      });
      const rows = results['rows'];
      this._miner = rows[0];
      
      if (this._lastTransactionTx !== this._miner.last_mine_tx) {
        const mining  = new Mining();
        this._miningResult = await mining.mine(client.userAccount, this._miner.last_mine_tx)
        this._lastTransactionTx = this._miner.last_mine_tx;
      }
  
    }
    catch (e) {
      console.error(e);
    }    
  }

  public getTimeLeft(lastMine:string = '2021-11-19T11:57:28') {
    const d = Date.parse(lastMine+'.000+00:00') / 1000;
    const cooldown = 40 * 60; // 39min TODO: must calculate with assets and land 
    const t = Date.now() / 1000 - d;
    return Math.round(cooldown-t);
  }

  public get miner() {
    return this._miner;
  }

  public loadAssets = async (): Promise<GameContract> => {
    await this.loadMiner();
    await this.loadBalances();
    this.updated();
    return this;
  }

  public updateAssets = async():Promise<GameContract> => {
    await this.loadMiner();
    await this.loadBalances();
    this.updated();
    return this;
  }  

  public async loadBalances(symbol:string='') {
    super.loadBalances(symbol);
  }

  public async autoActions(): Promise<TransactionAction[]> {
    await this.updateAssets();
    const client = this._client;
    const actions: TransactionAction[] = [];
    const timeLeft = this.getTimeLeft(this._miner.last_mine);
    if (timeLeft < 0 && this._miningResult.rand_str !== '') {
      actions.push(this.createGameActions(
        AlienWorldsContract.NAME,
        client.userAccount,
        "mine",
        {
          miner: client.userAccount,
          nonce: this._miningResult.rand_str,
        }          
      ));
      this._miningResult.rand_str = '';
    }
    return actions;
  }






}

