import { observer } from "mobx-react-lite";
import GameContract, { TokenBalance } from "../model/contract/GameContract";
import FarmersWorldContract from "../games/farmersworld/model/FarmersWorldcontract";
import FWBotView from "../games/farmersworld/view/FWBotView";
import FarmingTaleContract from "../games/farmingtales/model/FarmingTaleContract";
import FTBotView from "../games/farmingtales/view/FTBotView";
import CenturyTrainContract from "../games/centurytrain/model/CenturyTrainContract";
import CTBotView from "../games/centurytrain/view/CTBotView";
import AlienWorldsContract from "../games/alienworlds/model/AlienWorldsContract";
import AWBotView from "../games/alienworlds/view/AWBotView";
import NftPandaContract from "../games/nftpandawofg/model/NftPandaContract";
import NftPandaBotView from "../games/nftpandawofg/view/NftPandaBotView";

export interface GameProps  {
  game: GameContract
}

export interface BallanceProps {
  tokens: TokenBalance[]
}
    
const BalanceView = (props:BallanceProps): React.ReactElement => {
  const tokens = props.tokens;
  const items = tokens.map((e)=> {
    return <li key={e.symbol}><span>{e.symbol}</span>: {e.value}</li>
  })
  return (
    <div className="ballance">
      <ul>
        {items}
      </ul>    
    </div>
  )
}
/*
const MyStatelessComponent : React.FunctionComponent<MyProps> = props =>
    <div>
        <p>{props.propInMyProps}</p>
        <p>{props.children}</p>
    </div>
*/

function getChidren(game:GameContract) {
  switch(game.name) {
    case FarmersWorldContract.NAME:
      return <FWBotView game={game}></FWBotView>
    case FarmingTaleContract.NAME:
      return <FTBotView game={game}></FTBotView>
    case CenturyTrainContract.NAME:
      return <CTBotView game={game}></CTBotView>
    case AlienWorldsContract.NAME:
      return <AWBotView game={game}></AWBotView>
    case NftPandaContract.NAME:
      return <NftPandaBotView game={game}></NftPandaBotView>
      
    default:
      return <div>Special Content for game {game.name}</div>
  }
}

const GameView: React.FunctionComponent<GameProps> = props => {
  
  return     <div className="bot game-view">
              <h1>{ props.game.name } <span>({ Math.round(props.game.lastUpdateAt.getTime()/1000)})</span></h1>
              <BalanceView tokens={props.game.tokens}></BalanceView>
              { getChidren(props.game) }
            </div>
}


export default observer(GameView);