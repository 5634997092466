import { AnnotationsMap, makeObservable, observable, runInAction } from "mobx";
import EOSClient from "../RPCClient";

export interface HarvestAsset {
  id: string,
  timeLeft: number,
  label: string,
  collectionName: string
}

export interface TransactionAction {
  game: GameContract,
  account: string,
  name: string,
  authorization: [
    {
      actor: string,
      permission: string,
    },
  ],
  data: any
}

export interface TokenBalance {
  symbol: string,
  value: number
}

export default class GameContract {

  static NAME = '';
  static COLLECTION_NAME = '';
  protected _name: string = '';
  protected _contractName: string = '';
  protected _tokenContract: string = '';
  protected _defaultSymbol: string = '';
  protected _tokens: Record<string, TokenBalance>;
  protected _lastUpdateAt: Date;
  protected _selectedForBot: boolean;
  protected _client:EOSClient;

  constructor(client:EOSClient) {
    // console.log('[GameContract] constructor');
    this._name = '';
    this._contractName = '';
    this._tokenContract = '';
    this._defaultSymbol = '';
    this._selectedForBot = false;
    this._tokens = {};
    this._lastUpdateAt = new Date();
    this._client = client;
    makeObservable(this, {
      _lastUpdateAt: observable,
      _selectedForBot: observable,
    } as AnnotationsMap<this, string>);
  }

  public updated() {
    runInAction(() => {
      this._lastUpdateAt = new Date();
    })
  }

  public async loadBalances(symbol: string = '') {
    // farmingtoken   SEST
    // eosio.token    WAX
    // toc.century    TOCIUM
    // gciogamemint   GCM
    // simpleassets   DIESEL 
    // alien.worlds   TLM 
    // farmerstoken   FWF, FWG, FWW
    let tokens: string[] = [];
    if (symbol !== '') {
      tokens = await this._client.rpc.get_currency_balance(this._tokenContract, this._client.userAccount, symbol);
    }
    else {
      tokens = await this._client.rpc.get_currency_balance(this._tokenContract, this._client.userAccount);
    }
    //console.log(tokens);
    tokens.forEach((t) => {
      const a = t.split(' ');
      this._tokens[a[1]] = { symbol: a[1], value: parseFloat(a[0]) }
    })
    // console.log(this._tokens);
  }

  public get clien() {
    return this._client;
  }

  public get isSelectedForBot(): boolean {
    return this._selectedForBot;
  }
  
  public set selectedForBot(value: boolean) {
    runInAction(()=> {
      this._selectedForBot = value;
    });
  }

  public get lastUpdateAt(): Date {
    return this._lastUpdateAt;
  }

  public get name(): string {
    return this._name;
  }
  public set name(value: string) {
    this._name = value;
  }

  public get tokens(): TokenBalance[] {
    const tokens: TokenBalance[] = [];
    for (let key in this._tokens) {
      tokens.push(this._tokens[key]);
    }
    return tokens;
  }

  public async loadAssets(): Promise<GameContract> {
    return this;
  }

  public async updateAssets(): Promise<GameContract> {
    return this;
  }

  public async listHarvest(): Promise<HarvestAsset[]> {
    return [];
  }

  public async autoActions(): Promise<TransactionAction[]> {
    return [];
  }

  public createGameActions(account: string, actor: string, actionName: string, data: any): TransactionAction {
    return {
      game: this,
      account: account,
      name: actionName,
      authorization: [
        {
          actor: actor,
          permission: "active",
        },
      ],
      data: data
    }
  }

  public async finalizeAction(action: any) {
    console.log('finalizeAction');
  }

  public async runTransaction(action: any) {
    const client = this._client;
    try {
      const result = await client.api!.transact(
        {
          actions: [
            action
          ],
        },
        {
          blocksBehind: 3,
          expireSeconds: 1200,
        }
      );
      return result;      
    } catch (e) {
      console.error(e);
    }
    return null;
  }

}