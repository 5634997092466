import React from "react";
import { GameProps } from "../../../components/GameView";
import { timeHourToString } from "../../../model/Tools";
import NftPandaContract from "../model/NftPandaContract";

const NftPandaBotView: React.FunctionComponent<GameProps> = props => {

  const contract = props.game as NftPandaContract;
  
  const slots = Array.from(contract.slots.values()).map((slot) => {
    return (
        <div key={slot.asset_id}>Slot enery: { slot.energy/100 + '/100'} timeLeft: { timeHourToString(contract.getTimeleft(slot)) } </div>
      )
  });
  return (
    <div>      
      Panda 
      { slots }
    </div>
  )
}

export default NftPandaBotView;