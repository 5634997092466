import { WaxJS } from "@waxio/waxjs/dist";
import { Api, JsonRpc } from "eosjs";

export interface RPCClient {
  rpc: JsonRpc,
  api?: Api,
  userAccount: string,
}

export default class EOSClient implements RPCClient {
  protected _rpc:JsonRpc;
  protected _api:Api|undefined;
  protected _userAccount: string;

  constructor() {
    this._rpc = new JsonRpc('');
    this._api = undefined;
    this._userAccount = ''
  }

  public login() {

  }

  public get api(): Api|undefined {
    return this._api;
  }

  public get rpc(): JsonRpc {
    return this._rpc;
  }

  public get userAccount(): string {
    return this._userAccount;
  }

}

export class WaxClient extends EOSClient {

  protected _wax: WaxJS;

  constructor() {
    super();
    this._wax = new WaxJS({
      rpcEndpoint: 'https://chain.wax.io',
      tryAutoLogin: false,
    });
  }

  public async login() {
    try {
      await this._wax.login();
    }
    catch (e) {
      console.error(e);
    }
  }

  public get wax () {
    return this._wax;
  }

  public get api() {
    return this._wax.api;
  }

  public get rpc() {
    return this._wax.rpc;
  }

  public get userAccount() {
    return this._wax.userAccount;
  }

}