import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import UserStore from "../model/UserStore";
import GameContract from "../model/contract/GameContract";
import GameView from "../components/GameView";


const BotView = observer((): React.ReactElement => {
  const userStore = UserStore.getInstance();
  // const [bot, setBot] = useState<Bot>(new Bot());

  useEffect(() => {
    if (userStore.isLogged()) {
      const updateGame = async () => {
        await userStore.defaultBot.init()
        userStore.defaultBot.startWatch();
        //await bot.init()
        //bot.startWatch();
      };
      updateGame();
    }
    return () => {
      userStore.defaultBot.stopWatch();
      /*
      if (bot) {
        bot.stopWatch();
      }
      */
    }
  }, [userStore]);

  const bot = userStore.defaultBot;
  const games = Array.from(bot?.games.values()).map((game: GameContract) => {
    if (game.isSelectedForBot) {
      return <GameView key={game.name} game={game} />
    }
    return <div key={game.name}></div>;
  });

  let lastMinute = bot?.lastUpdate.getMinutes().toString();
  lastMinute = lastMinute.length === 1 ? '0'+lastMinute : lastMinute;
  let lastHour = bot?.lastUpdate.getHours().toString();
  lastHour = lastHour.length === 1 ? '0'+lastHour : lastHour;
  return (
      <div className="container bot">
        { userStore.defaultBot.isReady 
          ? ( <div className="bot-content">
                <h1>Bots <span>(Last update { lastHour+":"+lastMinute + " " +bot?.lastUpdate.toLocaleDateString()})</span></h1>                
                { games }
            </div>
          )
          : (<div>Waiting</div>)
        }
    </div>
  );
});

export default BotView;
